import type {FC} from "react";
import type {RouteObject} from "react-router";

import {lazy} from "react";
import {Navigate} from "react-router";
import {useRoutes} from "react-router-dom";

import Dashboard from "../pages/dashboard";
import LayoutPage from "../pages/layout";

import WrapperRouteComponent from "./config";
import Commands from "../features/TT_ELD_Admin/User/Users/User/Commands";
import RejectedList from "../features/TT_ELD_Admin/Approval/RejectedUsers/RejectedList";
import ActiveLoads from "../features/All_TMS_Admin/AdminApp/ActiveLoad/ActiveLoads";
import LoadHistories from "../features/All_TMS_Admin/AdminApp/LoadHistories/LoadHistories";
import LoadFiles from "../features/All_TMS_Admin/AdminApp/LoadFiles/LoadFiles";
import Note from "../features/All_TMS_Admin/AdminApp/Note/Note";
import SalaryList from "../features/All_TMS_Admin/AdminApp/SalaryList/SalaryList";
import DeviceVersion from "../features/TT_ELD_Admin/Team/DeviceVersion/Device-version";
import VehicleTabs from "../features/TT_ELD_Admin/User/Vehicles/VehicleData/VehicleEdit/VehicleTabs";
import ELD from "../features/TT_ELD_Admin/User/ELD Models/ELD";
import ELDEdit from "../features/TT_ELD_Admin/User/ELD Models/ELDEdit";
import DisputedCompanies from "../features/TT_ELD_Admin/User/Companies/DisputedCompanies";

const Login = lazy(() => import("../features/Auth/Login"));

const NotFound = lazy(() => import("../pages/404"));
// const Documentation = lazy(() => import("../pages/doucumentation"));
const Approval = lazy(() => import("../features/TT_ELD_Admin/Approval/Approval"));
const FMCSA = lazy(() => import("../features/TT_ELD_Admin/FMCSA/FMCSA"));
const UserCompanies = lazy(() => import("../features/TT_ELD_Admin/UserCompanies/UserCompanies"));
const UserCompaniesEdit = lazy(() => import("../features/TT_ELD_Admin/UserCompanies/UserCompaniesEdit/UserCompaniesEdit"));
const Contacts = lazy(() => import("../features/TT_ELD_Admin/Contact/Contact"));
const LogsEdit = lazy(() => import("../features/TT_ELD_Admin/User/Users/Logs/Log/LogEdit"));
const Users = lazy(() => import("../features/TT_ELD_Admin/User/Users/Users"));
const Dispatcher = lazy(() => import("../features/TT_ELD_Admin/User/Users/Dispatcher"));
const Admins = lazy(() => import("../features/TT_ELD_Admin/User/Admin/Admin"));
// const Admins = lazy(() => import("../features/TT_ELD_Admin/User/Users/Admins"));
const AdminsEdit = lazy(() => import("../features/TT_ELD_Admin/User/Admin/AdminEdit"));
const User = lazy(() => import("../features/TT_ELD_Admin/User/Users/User/User"));
const LogEdit = lazy(() => import("../features/TT_ELD_Admin/User/Users/Logs/Log/LogEdit"));
const Supports = lazy(() => import("../features/TT_ELD_Admin/User/Support/Supports"));
const Companies = lazy(() => import("../features/TT_ELD_Admin/User/Companies/Companies"));
const DisabledCompanies = lazy(() => import("../features/TT_ELD_Admin/User/Companies/DiabledCompanies"));
const Company = lazy(() => import("../features/TT_ELD_Admin/User/Companies/Company/Company"));
const Owners = lazy(() => import("../features/TT_ELD_Admin/User/Owner/Owners"));
const Vehicles = lazy(() => import("../features/TT_ELD_Admin/User/Vehicles/VehiclesCompany"));
const VehicleEdit = lazy(() => import("../features/TT_ELD_Admin/User/Vehicles/VehicleData/VehicleEdit/VehicleEdit"));
const Makes = lazy(() => import("../features/TT_ELD_Admin/User/Make - Model/Make/Make"));
const MakeEdit = lazy(() => import("../features/TT_ELD_Admin/User/Make - Model/Make/MakeEdit"));
const Models = lazy(() => import("../features/TT_ELD_Admin/User/Make - Model/Model/Model"));
const ModelEdit = lazy(() => import("../features/TT_ELD_Admin/User/Make - Model/Model/ModelEdit"));
const Documents = lazy(() => import("../features/TT_ELD_Admin/User/Users/User/Documents"));
const LogBook = lazy(() => import("../features/TT_ELD_Admin/User/LogBook/LogBook"));
const LogBookFind = lazy(() => import("../features/TT_ELD_Admin/User/LogBook/LogBookEdit"));
const CompanyErrors = lazy(() => import("../features/TT_ELD_Admin/CompanyErrors/CompanyErrors/CompanyErrors"));
const CompanyErrorMain = lazy(() => import("../features/TT_ELD_Admin/CompanyErrors/CompanyErrors/SupportHistory/SupportMain"));
const TopErrors = lazy(() => import("../features/TT_ELD_Admin/CompanyErrors/TopErrorsCompany/TopErrors"));
const Histories = lazy(() => import("../features/TT_ELD_Admin/Team/Histories/Histories"));
const ServerConfiguration = lazy(() => import("../features/TT_ELD_Admin/Team/ServerConfiguration/ServerConfiguration"));
const DevicesEld = lazy(() => import("../features/TT_ELD_Admin/Team/DevicesEld/DevicesEld"));
const System = lazy(() => import("../features/TT_ELD_Admin/Team/System/System"));
const Subscription = lazy(() => import("../features/TT_ELD_Admin/Team/Subscription/Subscription"));
const CheckStripe = lazy(() => import("../features/TT_ELD_Admin/Team/StripeCheck/CheckStripe"));
const Builds = lazy(() => import("../features/TT_ELD_Admin/Team/Builds/Builds"));
const Provider = lazy(() => import("../features/TT_ELD_Admin/Team/Provider/Provider"));
const ProviderEdit = lazy(() => import("../features/TT_ELD_Admin/Team/Provider/ProviderTable/ProviderEdit"));
const ProviderActivity = lazy(() => import("../features/TT_ELD_Admin/Team/ProviderActivity/ProviderActivity"));
const ProviderRequest = lazy(() => import("../features/TT_ELD_Admin/Team/ProviderRequest/ProviderRequest"));
const Load = lazy(() => import("../features/All_TMS_Admin/LoadController/LoadController"));
const LoadEdit = lazy(() => import("../features/All_TMS_Admin/LoadController/LoadControllerTable/LoadControllerEdit"));
const AddLoad = lazy(() => import("../features/All_TMS_Admin/LoadController/LoadControllerTable/LoadControllerAdd"));
const Broker = lazy(() => import("../features/All_TMS_Admin/BrokerController/BrokerController"));
const BrokerEdit = lazy(() => import("../features/All_TMS_Admin/BrokerController/BrokerControllerTable/BrokerControllerEdit"));
const AddBroker = lazy(() => import("../features/All_TMS_Admin/BrokerController/BrokerControllerTable/AddBrokerController"));
const City = lazy(() => import("../features/All_TMS_Admin/CityController/CityController"));
const CityEdit = lazy(() => import("../features/All_TMS_Admin/CityController/CityControllerTable/CityControllerEdit"));
const AddCity = lazy(() => import("../features/All_TMS_Admin/CityController/CityControllerTable/AddCityController"));
const Location = lazy(() => import("../features/All_TMS_Admin/CustomerController/CustomerController"));
const LocationEdit = lazy(() => import("../features/All_TMS_Admin/CustomerController/CustomerControllerTable/CustomerControllerEdit"));
const AddLocation = lazy(() => import("../features/All_TMS_Admin/CustomerController/CustomerControllerTable/AddCustomerController"));
const Tag = lazy(() => import("../features/All_TMS_Admin/TagController/Tag"));
const TagEdit = lazy(() => import("../features/All_TMS_Admin/TagController/TagControllerTable/TagControllerEdit"));
const AddTag = lazy(() => import("../features/All_TMS_Admin/TagController/TagControllerTable/AddTagController"));
const ZipCode = lazy(() => import("../features/All_TMS_Admin/ZipCode/ZipCode"));
const ZipCodeEdit = lazy(() => import("../features/All_TMS_Admin/ZipCode/ZipCodeTable/ZipCodeEdit"));
const AddZipCode = lazy(() => import("../features/All_TMS_Admin/ZipCode/ZipCodeTable/AddZipCode"));
const Category = lazy(() => import("../features/All_TMS_Admin/Category/Category"));
const CategoryEdit = lazy(() => import("../features/All_TMS_Admin/Category/CategoryTable/CategoryEdit"));
const Contact = lazy(() => import("../features/All_TMS_Admin/Contact/Contact"));
const ContactEdit = lazy(() => import("../features/All_TMS_Admin/Contact/ContactTable/ContactEdit"));
const AddContact = lazy(() => import("../features/All_TMS_Admin/Contact/ContactTable/AddContact"));
const Device = lazy(() => import("../features/All_TMS_Admin/Device/Device"));
const DeviceEdit = lazy(() => import("../features/All_TMS_Admin/Device/DeviceTable/DeviceEdit"));
const AddDevice = lazy(() => import("../features/All_TMS_Admin/Device/DeviceTable/AddDevice"));
const Report = lazy(() => import("../features/All_TMS_Admin/Report/Report"));
const Unit = lazy(() => import("../features/All_TMS_Admin/Unit/Report"));
const UnitGetInfo = lazy(() => import("../features/All_TMS_Admin/Unit/ReportTable/GetInfo"));

const routeList: RouteObject[] = [
    {path: "/login", element: (<WrapperRouteComponent element={<Login/>} titleId="title.login"/>),},
    {path: "/", element: <WrapperRouteComponent element={<LayoutPage/>} titleId=""/>, children: [{path: "", element: <Navigate to="account/approval"/>,},
            // {path: 'dashboard', element: <WrapperRouteComponent element={<Dashboard/>} titleId="title.dashboard"/>,},
            // {path: 'documentation', element: <WrapperRouteComponent element={<Documentation/>} titleId="title.documentation"/>,},
            {path: "account/approval", element: (<WrapperRouteComponent element={<Approval/>} titleId="title.approval"/>),},
            {path: "account/rejected-users", element: (<WrapperRouteComponent element={<RejectedList/>} titleId="title.rejected-users"/>),},
            {path: "fmcsa", element: (<WrapperRouteComponent element={<FMCSA/>} titleId="title.fmcsa"/>),},
            {path: "contact", element: (<WrapperRouteComponent element={<Contacts />} titleId="title.contacts" />),},
            {path: "disabled-companies", element: (<WrapperRouteComponent element={<DisabledCompanies/>} titleId="title.disabledCompany"/>),},
            {path: "disabled-companies/:id", element: (<WrapperRouteComponent element={<Company/>} titleId="title.companies"/>),},
            {path: "disabled-companies/:id/:id", element: (<WrapperRouteComponent element={<VehicleEdit vehicleId=''/>} titleId="title.companies"/>),},
            {path: "disputed-companies", element: (<WrapperRouteComponent element={<DisputedCompanies/>} titleId="title.disabledCompany"/>),},
            {path: "disputed-companies/:id", element: (<WrapperRouteComponent element={<Company/>} titleId="title.companies"/>),},
            {path: "disputed-companies/:id/:id", element: (<WrapperRouteComponent element={<VehicleEdit vehicleId=''/>} titleId="title.companies"/>),},
            {path: "logs/log/:logId/:id", element: (<WrapperRouteComponent element={<LogsEdit/>} titleId="title.logsEdit"/>),},
            {path: "logs/logzeros/:logId/:id", element: (<WrapperRouteComponent element={<LogsEdit/>} titleId="title.logsEdit"/>),},
            {path: "logs/logzeros/:logId", element: (<WrapperRouteComponent element={<LogsEdit/>} titleId="title.logsEdit"/>),},
            {path: "user-companies", element: (<WrapperRouteComponent element={<UserCompanies uid={''}/>} titleId="title.userCompanies"/>),},
            {path: "user-companies/:id", element: (<WrapperRouteComponent element={<UserCompaniesEdit />} titleId="title.userCompanies"/>),},
            {path: "user/supports", element: (<WrapperRouteComponent element={<Supports/>} titleId="title.supports"/>),},
            {path: "user/dispatcher", element: (<WrapperRouteComponent element={<Dispatcher/>} titleId="title.users"/>),},
            {path: "user/admins", element: (<WrapperRouteComponent element={<Admins/>} titleId="title.users"/>),},
            {path: "user/admins/:id", element: (<WrapperRouteComponent element={<AdminsEdit/>} titleId="title.users"/>),},
            {path: "user/users", element: (<WrapperRouteComponent element={<Users/>} titleId="title.users"/>),},
            {path: "user/users/:id", element: (<WrapperRouteComponent element={<User/>} titleId="title.user/:id"/>),},
            {path: "user/users/:id/log/:logId/:id", element: (<WrapperRouteComponent element={<LogEdit/>} titleId="title.logs/:id"/>),},
            {path: "user/users/:id/logzeros/:logId/:id", element: (<WrapperRouteComponent element={<LogEdit/>} titleId="title.logs/:id"/>),},
            {path: "user/companies", element: (<WrapperRouteComponent element={<Companies/>} titleId="title.companies"/>),},
            {path: "user/companies/:id", element: (<WrapperRouteComponent element={<Company/>} titleId="title.companyEdit"/>),},
            {path: "user/companies/:id/:id", element: (<WrapperRouteComponent element={<VehicleEdit vehicleId=''/>} titleId="title.companyEdit"/>),},
            {path: "user/owners", element: (<WrapperRouteComponent element={<Owners/>} titleId="title.owners"/>),},
            {path: "user/owners/:id", element: (<WrapperRouteComponent element={<UserCompanies uid={''}/>} titleId="title.owners"/>),},
            {path: "user/vehicles", element: (<WrapperRouteComponent element={<Vehicles/>} titleId="title.vehicles"/>),},
            {path: "user/vehicles/:id", element: (<WrapperRouteComponent element={<VehicleTabs vehicleId=''/>} titleId="title.vehicleEdit"/>),},
            {path: "user/makes", element: (<WrapperRouteComponent element={<Makes/>} titleId="title.makes"/>),},
            {path: "user/makes/:id", element: (<WrapperRouteComponent element={<MakeEdit/>} titleId="title.makeEdit"/>),},
            {path: "user/models", element: (<WrapperRouteComponent element={<Models/>} titleId="title.models"/>),},
            {path: "user/models/:id", element: (<WrapperRouteComponent element={<ModelEdit/>} titleId="title.modelEdit"/>),},
            {path: "user/documents", element: (<WrapperRouteComponent element={<Documents/>} titleId="title.documents"/>),},
            {path: "user/eld_model", element: (<WrapperRouteComponent element={<ELD/>} titleId="title.eld"/>),},
            {path: "user/eld_model/:id", element: (<WrapperRouteComponent element={<ELDEdit/>} titleId="title.eld"/>),},
            {path: "user/logbook", element: (<WrapperRouteComponent element={<LogBook/>} titleId="title.logbook"/>),},
            {path: "user/logbook/:id", element: (<WrapperRouteComponent element={<LogBookFind/>} titleId="title.logbook"/>),},
            {path: "errors/company-errors", element: (<WrapperRouteComponent element={<CompanyErrors/>} titleId="title.company-errors"/>),},
            {path: "errors/company-errors/:id", element: (<WrapperRouteComponent element={<CompanyErrorMain/>} titleId="title.company-errors"/>),},
            {path: "errors/company-errors/:id/:id", element: (<WrapperRouteComponent element={<UserCompanies uid={''}/>} titleId="title.company-errors"/>),},
            {path: "errors/top-errors", element: (<WrapperRouteComponent element={<TopErrors/>} titleId="title.topErrors"/>),},
            {path: "errors/top-errors/:id", element: (<WrapperRouteComponent element={<CompanyErrorMain/>} titleId="title.topErrors/:id"/>),},
            {path: "errors/top-errors/:id/:id", element: (<WrapperRouteComponent element={<UserCompanies uid={''}/>} titleId="title.top-errors"/>),},
            {path: "team/histories", element: (<WrapperRouteComponent element={<Histories/>} titleId="title.histories"/>),},
            {path: "team/server-configuration", element: (<WrapperRouteComponent element={<ServerConfiguration/>} titleId="title.serverConfiguration"/>),},
            {path: "team/devices-eld", element: (<WrapperRouteComponent element={<DevicesEld/>} titleId="title.devicesEld"/>),},
            {path: "team/devices-eld/:id", element: (<WrapperRouteComponent element={<DevicesEld/>} titleId="title.devicesEld"/>),},
            {path: "team/system", element: (<WrapperRouteComponent element={<System/>} titleId="title.system"/>),},
            {path: "team/subscription", element: (<WrapperRouteComponent element={<Subscription/>} titleId="title.subscription"/>),},
            {path: "team/device-version", element: (<WrapperRouteComponent element={<DeviceVersion/>} titleId="title.deviceVersion"/>),},
            {path: "team/check-stripe", element: (<WrapperRouteComponent element={<CheckStripe/>} titleId="title.check-stripe"/>),},
            {path: "team/check-stripe/:id", element: (<WrapperRouteComponent element={<Company/>} titleId="title.check-stripe"/>),},
            {path: "team/check-stripe/:id/:id", element: (<WrapperRouteComponent element={<VehicleEdit vehicleId=''/>} titleId="title.check-stripe"/>),},
            // {path: "team/builds", element: (<WrapperRouteComponent element={<Builds/>} titleId="title.builds"/>),},
            {path: "team/provider", element: (<WrapperRouteComponent element={<Provider/>} titleId="title.provider"/>),},
            {path: "team/provider/:id", element: (<WrapperRouteComponent element={<ProviderEdit/>} titleId="title.provider"/>),},
            {path: "team/provider-activity/", element: (<WrapperRouteComponent element={<ProviderActivity/>} titleId="title.provider-activity"/>),},
            {path: "team/provider-request/", element: (<WrapperRouteComponent element={<ProviderRequest/>} titleId="title.provider-request"/>),},
            {path: "tms/active-loads", element: (<WrapperRouteComponent element={<ActiveLoads/>} titleId="title.load"/>),},
            {path: "tms/load-histories", element: (<WrapperRouteComponent element={<LoadHistories/>} titleId="title.load"/>),},
            {path: "tms/load-files", element: (<WrapperRouteComponent element={<LoadFiles/>} titleId="title.load"/>),},
            {path: "tms/note", element: (<WrapperRouteComponent element={<Note/>} titleId="title.load"/>),},
            {path: "tms/salary-list", element: (<WrapperRouteComponent element={<SalaryList/>} titleId="title.load"/>),},
            {path: "tms/load", element: (<WrapperRouteComponent element={<Load/>} titleId="title.load"/>),},
            {path: "tms/load/:id", element: (<WrapperRouteComponent element={<LoadEdit/>} titleId="title.loadEdit"/>),},
            {path: "tms/load/add-load", element: (<WrapperRouteComponent element={<AddLoad/>} titleId="title.addLoad"/>),},
            {path: "tms/broker", element: (<WrapperRouteComponent element={<Broker/>} titleId="title.broker"/>),},
            {path: "tms/broker/:id", element: (<WrapperRouteComponent element={<BrokerEdit/>} titleId="title.brokerEdit"/>),},
            {path: "tms/broker/add-broker", element: (<WrapperRouteComponent element={<AddBroker/>} titleId="title.addBroker"/>),},
            {path: "tms/city", element: (<WrapperRouteComponent element={<City/>} titleId="title.city"/>),},
            {path: "tms/city/:id", element: (<WrapperRouteComponent element={<CityEdit/>} titleId="title.cityEdit"/>),},
            {path: "tms/city/add-city", element: (<WrapperRouteComponent element={<AddCity/>} titleId="title.addCity"/>),},
            {path: "tms/location", element: (<WrapperRouteComponent element={<Location/>} titleId="title.location"/>),},
            {path: "tms/location/:id", element: (<WrapperRouteComponent element={<LocationEdit/>} titleId="title.locationEdit"/>),},
            {path: "tms/location/add-location", element: (<WrapperRouteComponent element={<AddLocation/>} titleId="title.addLocation"/>),},
            {path: "tms/tag", element: (<WrapperRouteComponent element={<Tag/>} titleId="title.tag"/>),},
            {path: "tms/tag/:id", element: (<WrapperRouteComponent element={<TagEdit/>} titleId="title.tagEdit"/>),},
            {path: "tms/tag/add-tag", element: (<WrapperRouteComponent element={<AddTag/>} titleId="title.addTag"/>),},
            {path: "tms/zip-code", element: (<WrapperRouteComponent element={<ZipCode/>} titleId="title.zipCode"/>),},
            {path: "tms/zip-code/:id", element: (<WrapperRouteComponent element={<ZipCodeEdit/>} titleId="title.zipCodeEdit"/>),},
            {path: "tms/zip-code/add-zip-code", element: (<WrapperRouteComponent element={<AddZipCode/>} titleId="title.addZipCode"/>),},
            {path: "tms/category", element: (<WrapperRouteComponent element={<Category/>} titleId="title.category"/>),},
            {path: "tms/category/:id", element: (<WrapperRouteComponent element={<CategoryEdit/>} titleId="title.categoryEdit"/>),},
            {path: "tms/contact", element: (<WrapperRouteComponent element={<Contact/>} titleId="title.contact"/>),},
            {path: "tms/contact/:id", element: (<WrapperRouteComponent element={<ContactEdit/>} titleId="title.contactEdit"/>),},
            {path: "tms/contact/add-contact", element: (<WrapperRouteComponent element={<AddContact/>} titleId="title.addContact"/>),},
            {path: "tms/device", element: (<WrapperRouteComponent element={<Device/>} titleId="title.device"/>),},
            {path: "tms/device/:id", element: (<WrapperRouteComponent element={<DeviceEdit/>} titleId="title.deviceEdit"/>),},
            {path: "tms/device/add-device", element: (<WrapperRouteComponent element={<AddDevice/>} titleId="title.addDevice"/>),},
            {path: "tms/report", element: (<WrapperRouteComponent element={<Report/>} titleId="title.report"/>),},
            {path: "tms/unit", element: (<WrapperRouteComponent element={<Unit/>} titleId="title.report"/>),},
            // {path: "tms/unit/:id", element: (<WrapperRouteComponent element={<UnitGetInfo/>} titleId="title.report"/>),},
            {path: "*", element: (<WrapperRouteComponent element={<NotFound/>} titleId="title.notFount"/>),},
        ],
    },
];


const RenderRouter: FC = () => {
    const element = useRoutes(routeList);

    return element;
};

export default RenderRouter;
